'use strict';

var R = require(29);

var CNET = require(78);

var fixes = require(13);
var deliveryInfo = require(12);
var christmasReturn = require(11);
var headerMessage = require(15);
var stockInfo = require(23);
var productTile = require(21);
var outlet = require(20);
var rememberToBuy = require(22);

function scaffolding() {

    // update stock info on product card
    var product = document.querySelector('.js-productCard');
    stockInfo.update(product);

    // fetch data from CNET and display gallery, KSP, energy badge and rich content if available
    var Manufactors = document.querySelector('.Manufactors').textContent;
    var VendorNumber = document.querySelector('.VendorNumber').textContent;

    CNET.cnetInit(Manufactors, VendorNumber);
    CNET.loadWidget(Manufactors, VendorNumber);

}

R(R.EVENTS.LOAD, [R.SECTIONS.PRODUCT_PAGE], function() {
    christmasReturn.productPageBanner();
    headerMessage.showMessage();
    deliveryInfo.init();
    productTile.update();
    outlet.linkProducts();
    outlet.buildOutletLabel();
    fixes.productPageFixes();
    fixes.productPageB2bFixes();
    rememberToBuy.init();
    scaffolding();
    R.fire(R.EVENTS.POST_LOAD);
});
