'use script';

var tileParser = require(58);
var productParser = require(59);
var formatter = require(64);

var smoothScroll = require(30);


/**
 * On product page see if there are any outlet products in related products with cheaper price and
 * in stock, if true display link with price
 */
function linkProducts() {

    // check if there are any related products
    if ($('.js-productRelated').length && !$('.js-productRelated .Related_Custom_DIV').children().length) {
        return;
    }

    var allRelatedProducts = $('.js-productRelated .Related_Custom_DIV').children();

    // find matching outlet products in stock
    var outletProducts = allRelatedProducts.filter(function(index, product) {
        var productData = tileParser.parseProduct($(product));
        var isMatchingOutletProduct = _isMatchingOutletProduct(productData.number);
        var isInStock = _isInStock(productData.stock);
        if (isMatchingOutletProduct && isInStock) {
            return true;
        } else {
            return false;
        }
    });

    if (!outletProducts.length) {
        return;
    }

    // find cheapest outlet product
    var cheapestOutletProduct;
    outletProducts.each(function(index, product) {
        var productData = tileParser.parseProduct($(product));
        if (!cheapestOutletProduct || productData.price < cheapestOutletProduct.price) {
            cheapestOutletProduct = productData;
        }
    });

    // get main product price
    var currentProduct = productParser.parseProduct($('.js-productCard'));

    // if outlet product is cheaper, show link
    if (cheapestOutletProduct.price < currentProduct.price) {
        _buildPromotion(cheapestOutletProduct);
    }

}

function _isMatchingOutletProduct(number) {
    if (number &&
        number.indexOf(window.ProductNumber) > -1 &&
        number.indexOf('B-') > -1) {
        return true;
    } else {
        return false;
    }
}

function _isInStock(stock) {
    return parseInt(stock) > 0;
}

function _buildPromotion(outletProduct) {

    var container = $('.js-outletPromotion');
    var link = document.createElement('a');
    var price = formatter(outletProduct.price);

    link.href = outletProduct.link;
    link.classList.add('c-outlet');
    link.classList.add('c-outlet-link');

    // @TODO: extract copy in data and markup into template
    link.innerHTML = '<span class="c-outlet-link__copy">Få den til <strong>KUN ' + price + '</strong> i vores outlet!</span><span class="c-outlet-link__action">Se mere<i class="icon-arrow-right">&nbsp;</i></span>';

    container.append(link);
    container.removeClass('u-hidden');
}

/**
 * label displayed below product title if product is outlet (B- prefix in ProductNumber)
 */
function buildOutletLabel() {
    // Add outlet label
    if (!_isMatchingOutletProduct(window.ProductNumber)) {
        return;
    }

    var title = $('.js-productTitle');
    var el = document.createElement('div');
    el.classList.add('c-outlet');
    el.classList.add('c-outlet-label');

    // @TODO: extract copy in data and markup into template
    el.innerHTML = '<h3>Outlet</h3>' +
    '<p>Denne vare er returneret af en kunde eller har været repareret på et autoriseret værksted. Du har selvfølgelig stadig <strong>to års reklamationsret</strong> og <strong>14 dages returret</strong>. Læs mere om produktets <a href="#ProductSpecs">tilstand her</a></p>';

    title.append(el);

    // bind smoothScroll to label link
    smoothScroll();

}

/* test-code */
// export globally for react
window.linkProducts = linkProducts;
window.buildOutletLabel = buildOutletLabel;
/* end-test-code */

module.exports = {
    linkProducts: linkProducts,
    buildOutletLabel: buildOutletLabel
};
