require=(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({77:[function(require,module,exports){
'use strict';

var R = require(29);

var CNET = require(78);

var fixes = require(13);
var deliveryInfo = require(12);
var christmasReturn = require(11);
var headerMessage = require(15);
var stockInfo = require(23);
var productTile = require(21);
var outlet = require(20);
var rememberToBuy = require(22);

function scaffolding() {

    // update stock info on product card
    var product = document.querySelector('.js-productCard');
    stockInfo.update(product);

    // fetch data from CNET and display gallery, KSP, energy badge and rich content if available
    var Manufactors = document.querySelector('.Manufactors').textContent;
    var VendorNumber = document.querySelector('.VendorNumber').textContent;

    CNET.cnetInit(Manufactors, VendorNumber);
    CNET.loadWidget(Manufactors, VendorNumber);

}

R(R.EVENTS.LOAD, [R.SECTIONS.PRODUCT_PAGE], function() {
    christmasReturn.productPageBanner();
    headerMessage.showMessage();
    deliveryInfo.init();
    productTile.update();
    outlet.linkProducts();
    outlet.buildOutletLabel();
    fixes.productPageFixes();
    fixes.productPageB2bFixes();
    rememberToBuy.init();
    scaffolding();
    R.fire(R.EVENTS.POST_LOAD);
});

},{"11":11,"12":12,"13":13,"15":15,"20":20,"21":21,"22":22,"23":23,"29":29,"78":78}],22:[function(require,module,exports){
function init() {
    var root = $('.rtb-products')
    if (!root.length) return;
    addShadow(root);
    initRtb();
}

function addShadow(root) {
    var productLength = root.find('.rtb-product').length
    if (productLength <= 5) return;
    root.addClass('rtb-products_collapsed')
    root.append('<span class="rtb-expand"></span>')
    root.find('.rtb-expand').on('click', function() {
        root.removeClass('rtb-products_collapsed')
        root.addClass('rtb-products_visible')
        $(this).fadeOut();
    });
}

function initRtb() {
    var rtb;
    console.log('rtb start')
    if (window.ProductNumber !== '') { // productcard
        console.log('rtb product number check')
        // remember to buy
        if (window.ProductNumber !== window.ProductVariantMasterID) {
            $('.rtb-products input[type="checkbox"]').on('change',function() {
                console.log('change event')
                handleRtb();
            });
            
            var form = $('[itemtype="https://schema.org/Product"] > form[action="/shop/basket.asp"]').eq(0);
            $('#BUYSECTION .buyWrapper input').on('click',function(e) {
                e.preventDefault();
                if (window.canBuy && rtb !== undefined && rtb !== '') {
                    $(this).prop('disabled',true).addClass('rtb-loading');
                    $.get(rtb,function() {
                        form.submit(); 
                    });                
                }
                else {
                    form.submit();
                }
            });
        }
    }


    function handleRtb() {
        var pricecontainer = $('[itemtype="https://schema.org/Offer"] [itemprop="price"]');
        var totalprice = parseFloat(pricecontainer.attr('content'));
        console.log(pricecontainer)
        var ids = '';
        var amounts = '';
        $('.rtb-products input[type="checkbox"]:checked').each(function() {
            totalprice += parseFloat($(this).data('price'));
            var id = $(this).data('id');
            ids += id+'|';
            amounts += '1|'
        });
        
        if (ids !== '') {
            rtb = '/shop/showbasket.html?AddMultiple=1&ProductID='+ids+'&Amount='+amounts;
        }
        else {
            rtb = '';
        }
        console.log(totalprice)
        pricecontainer.html(totalprice.numberFormat(2));
    }
}

Number.prototype.numberFormat = function (decimals, dec_point, thousands_sep) {
    dec_point = typeof dec_point !== 'undefined' ? dec_point : ',';
    thousands_sep = typeof thousands_sep !== 'undefined' ? thousands_sep : '.';

    var parts = this.toFixed(decimals).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

    return parts.join(dec_point);
};

module.exports = {
    init: init
}
},{}],20:[function(require,module,exports){
'use script';

var tileParser = require(58);
var productParser = require(59);
var formatter = require(64);

var smoothScroll = require(30);


/**
 * On product page see if there are any outlet products in related products with cheaper price and
 * in stock, if true display link with price
 */
function linkProducts() {

    // check if there are any related products
    if ($('.js-productRelated').length && !$('.js-productRelated .Related_Custom_DIV').children().length) {
        return;
    }

    var allRelatedProducts = $('.js-productRelated .Related_Custom_DIV').children();

    // find matching outlet products in stock
    var outletProducts = allRelatedProducts.filter(function(index, product) {
        var productData = tileParser.parseProduct($(product));
        var isMatchingOutletProduct = _isMatchingOutletProduct(productData.number);
        var isInStock = _isInStock(productData.stock);
        if (isMatchingOutletProduct && isInStock) {
            return true;
        } else {
            return false;
        }
    });

    if (!outletProducts.length) {
        return;
    }

    // find cheapest outlet product
    var cheapestOutletProduct;
    outletProducts.each(function(index, product) {
        var productData = tileParser.parseProduct($(product));
        if (!cheapestOutletProduct || productData.price < cheapestOutletProduct.price) {
            cheapestOutletProduct = productData;
        }
    });

    // get main product price
    var currentProduct = productParser.parseProduct($('.js-productCard'));

    // if outlet product is cheaper, show link
    if (cheapestOutletProduct.price < currentProduct.price) {
        _buildPromotion(cheapestOutletProduct);
    }

}

function _isMatchingOutletProduct(number) {
    if (number &&
        number.indexOf(window.ProductNumber) > -1 &&
        number.indexOf('B-') > -1) {
        return true;
    } else {
        return false;
    }
}

function _isInStock(stock) {
    return parseInt(stock) > 0;
}

function _buildPromotion(outletProduct) {

    var container = $('.js-outletPromotion');
    var link = document.createElement('a');
    var price = formatter(outletProduct.price);

    link.href = outletProduct.link;
    link.classList.add('c-outlet');
    link.classList.add('c-outlet-link');

    // @TODO: extract copy in data and markup into template
    link.innerHTML = '<span class="c-outlet-link__copy">Få den til <strong>KUN ' + price + '</strong> i vores outlet!</span><span class="c-outlet-link__action">Se mere<i class="icon-arrow-right">&nbsp;</i></span>';

    container.append(link);
    container.removeClass('u-hidden');
}

/**
 * label displayed below product title if product is outlet (B- prefix in ProductNumber)
 */
function buildOutletLabel() {
    // Add outlet label
    if (!_isMatchingOutletProduct(window.ProductNumber)) {
        return;
    }

    var title = $('.js-productTitle');
    var el = document.createElement('div');
    el.classList.add('c-outlet');
    el.classList.add('c-outlet-label');

    // @TODO: extract copy in data and markup into template
    el.innerHTML = '<h3>Outlet</h3>' +
    '<p>Denne vare er returneret af en kunde eller har været repareret på et autoriseret værksted. Du har selvfølgelig stadig <strong>to års reklamationsret</strong> og <strong>14 dages returret</strong>. Læs mere om produktets <a href="#ProductSpecs">tilstand her</a></p>';

    title.append(el);

    // bind smoothScroll to label link
    smoothScroll();

}


module.exports = {
    linkProducts: linkProducts,
    buildOutletLabel: buildOutletLabel
};

},{"30":30,"58":58,"59":59,"64":64}]},{},[77])

