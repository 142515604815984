function init() {
    var root = $('.rtb-products')
    if (!root.length) return;
    addShadow(root);
    initRtb();
}

function addShadow(root) {
    var productLength = root.find('.rtb-product').length
    if (productLength <= 5) return;
    root.addClass('rtb-products_collapsed')
    root.append('<span class="rtb-expand"></span>')
    root.find('.rtb-expand').on('click', function() {
        root.removeClass('rtb-products_collapsed')
        root.addClass('rtb-products_visible')
        $(this).fadeOut();
    });
}

function initRtb() {
    var rtb;
    console.log('rtb start')
    if (window.ProductNumber !== '') { // productcard
        console.log('rtb product number check')
        // remember to buy
        if (window.ProductNumber !== window.ProductVariantMasterID) {
            $('.rtb-products input[type="checkbox"]').on('change',function() {
                console.log('change event')
                handleRtb();
            });
            
            var form = $('[itemtype="https://schema.org/Product"] > form[action="/shop/basket.asp"]').eq(0);
            $('#BUYSECTION .buyWrapper input').on('click',function(e) {
                e.preventDefault();
                if (window.canBuy && rtb !== undefined && rtb !== '') {
                    $(this).prop('disabled',true).addClass('rtb-loading');
                    $.get(rtb,function() {
                        form.submit(); 
                    });                
                }
                else {
                    form.submit();
                }
            });
        }
    }


    function handleRtb() {
        var pricecontainer = $('[itemtype="https://schema.org/Offer"] [itemprop="price"]');
        var totalprice = parseFloat(pricecontainer.attr('content'));
        console.log(pricecontainer)
        var ids = '';
        var amounts = '';
        $('.rtb-products input[type="checkbox"]:checked').each(function() {
            totalprice += parseFloat($(this).data('price'));
            var id = $(this).data('id');
            ids += id+'|';
            amounts += '1|'
        });
        
        if (ids !== '') {
            rtb = '/shop/showbasket.html?AddMultiple=1&ProductID='+ids+'&Amount='+amounts;
        }
        else {
            rtb = '';
        }
        console.log(totalprice)
        pricecontainer.html(totalprice.numberFormat(2));
    }
}

Number.prototype.numberFormat = function (decimals, dec_point, thousands_sep) {
    dec_point = typeof dec_point !== 'undefined' ? dec_point : ',';
    thousands_sep = typeof thousands_sep !== 'undefined' ? thousands_sep : '.';

    var parts = this.toFixed(decimals).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

    return parts.join(dec_point);
};

module.exports = {
    init: init
}